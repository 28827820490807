.error-page {
  @include responsive-max-width-m {
    background-size: 110% auto;
  }

  background: url(https://i.imgur.com/qhMbkGi.jpg) no-repeat;
  background-position: right bottom;
  min-height: calc(100vh - #{rem-calc(88)});

  &__section {
    @include responsive-min-width-m {
      margin-top: 10vh;
    }
    
    @include responsive-min-width-l {
      margin-top: 20vh;
    }

    max-width: 375px;
    margin: 5vh auto 0;
  }

  &__title {
    @include responsive-min-width-m {
      font-size: rem-calc(140);
      margin-bottom:rem-calc(16);
      max-width: rem-calc(270);
    }

    background-color: $green;
    color: white;
    display: block;
    font-family: 'Gabriela', serif;
    font-size: rem-calc(90);
    letter-spacing: rem-calc(-5);
    line-height: 1;
    margin: 0 auto rem-calc(8);
    max-width: rem-calc(180);
    padding: 0 rem-calc(8);
    text-align: center;
    width: 100%;
  }

  p {
    text-align: center;
  }
}