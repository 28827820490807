@mixin clearfix {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
  & {
    *zoom: 1;
  }
}

.clearfix {
  @include clearfix;
}

.clear {
  clear:both;
}
