.workpage {
  margin-bottom: rem-calc(24);

  .container {
    @include responsive-max-width-l {
      padding-left: rem-calc(24);
      padding-right: rem-calc(24);
    }
    
    @include responsive-max-width-m {
      padding-left: rem-calc(8);
      padding-right: rem-calc(8);
    }
  }

  &__hero {
    @include responsive-min-width-m {
      border-radius: $default-border-radius;
      min-height: rem-calc(400);
    }

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: rem-calc(40);
    min-height: rem-calc(350);
    text-align: center;
    
    .elements-core & {
      background-image: url('../../images/work/elements-core/hero-bg.svg');
      background-color: $core;
    }
    
    .elements-search & {
      background-image: url('../../images/work/elements-search/hero-bg.svg');
      background-color: $search;
    }
    
    .elements-onboarding & {
      background-image: url('../../images/work/elements-onboarding/hero-bg.png');
      background-color: $onboarding;
    }
  }

  h2, 
  h3,
  h4 {
    font-family: $default-body-font;
    font-size: rem-calc(20);
    font-weight: 700;
    text-transform: uppercase;
  }

  &__title {
    margin-top: rem-calc(64);
    
    .elements-onboarding & {
      @include responsive-max-width-m {
        margin-top: rem-calc(32);
      }
    }
  }

  &__title,
  &__description {
    color: white;
    margin-bottom: rem-calc(8);
  }

  img {
    border-radius: $default-border-radius;
    display: block;
  }

  &__content-section {
    @include responsive-max-width-m {
      margin: rem-calc(40) rem-calc(16);
    }

    @include responsive-min-width-l {
      margin-left: 0;
      margin-right: 0;
      float: right;
    }

    @include clearfix;
    margin: rem-calc(64) auto rem-calc(80);
    max-width: rem-calc(640);
    font-size: rem-calc(18);
    line-height: 1.8;
  
    &:first-of-type {
      margin-top: rem-calc(16);
    }
  }
}

h2, 
h3,
h4 {
  .elements-core & {
    color: $core;
  }

  .elements-search & {
    color: $search;
  }

  .elements-onboarding & {
    color: $onboarding;
  }
}
