.container {
  @include responsive-min-width-l {
    padding-left: 3%;
    padding-right: 3%;
    max-width: calc(#{$max-width} + 6%);
  }

  @include clearfix;
  margin: 0 auto;
  max-width: calc(#{$max-width} + 4rem);
  padding-left: 2rem;
  padding-right: 2rem;
  width: 100%;
}

.row {
  @include clearfix;

  @include responsive-min-width-m {
    &--mid-res {
      align-items: center;
      display: flex;
      margin-left: -$grid-gutter-width;
      margin-right: -$grid-gutter-width;

      &.row--top {
        align-items: flex-start;
      }

      &.row--bottom {
        align-items: flex-end;
      }

      &.row--center {
        justify-content: center;
      }
    }
  }

  @include responsive-min-width-l {
    align-items: center;
    display: flex;
    margin-left: -$grid-gutter-width;
    margin-right: -$grid-gutter-width;

    &--top {
      align-items: flex-start;
    }

    &--bottom {
      align-items: flex-end;
    }

    &--center {
      justify-content: center;
    }
  }

  position: relative;

  &--no-align {
    align-items: stretch;
  }
}

.column {
  @for $i from 1 through $grid-column-count {
    &-#{$i} {
      min-height: 1px;
      position: relative;
      width: 100%;

      @include responsive-min-width-m {
        .row--mid-res & {
          margin-left: $grid-gutter-width;
          margin-right: $grid-gutter-width;
          width: (100% / $grid-column-count * $i) - ($grid-gutter-width * 2);
        }
      }

      @include responsive-min-width-l {
        margin-left: $grid-gutter-width;
        margin-right: $grid-gutter-width;
        width: (100% / $grid-column-count * $i) - ($grid-gutter-width * 2);
      }

      &--fixed {
        float: left;
        width: 100% / $grid-column-count * $i;

        @include responsive-min-width-l {
          float: none;
        }
      }
    }
  }

  &--align-left {
    text-align: left;
  }

  &--align-center {
    text-align: center;
  }

  &--align-right {
    text-align: right;
  }
}

@for $i from 1 through $grid-column-count {
  .order-#{$i} {
    order: $i;
  }
}
