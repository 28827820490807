// Stolen from Foundation framework

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

// New Syntax, allows to optionally calculate on a different base value to counter compounding effect of em\'s.
// Call with 1, 2, 3 or 4 parameters, \'px\' is not required but supported
// rem-calc(10 20 30px 40);
// Space delimited, if you want to delimit using comma\'s, wrap it in another pair of brackets
// rem-calc((10, 20, 30, 40px));
// Optionally call with a different base (eg: 8px) to calculate em.
// rem-calc(16px 32px 48px, 8px);
// If you require to comma separate your list
// rem-calc((16px, 32px, 48), 8px);

@function convert-to-rem($value, $base-value: $rem-base)  {
  $value: strip-unit($value) / strip-unit($base-value) * 1rem;
  @if ($value == 0rem) { $value: 0; } // Turn 0rem into 0
  @return $value;
}

@function rem-calc($values, $base-value: $rem-base) {
  $max: length($values);

  @if $max == 1 { @return convert-to-rem(nth($values, 1), $base-value); }

  $remValues: ();
  @for $i from 1 through $max {
    $remValues: append($remValues, convert-to-rem(nth($values, $i), $base-value));
  }
  @return $remValues;
}

// Support for the old syntax

@function emCalc($values){
  @return rem-calc($values);
}