*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: $default-font-size;
  height: 100%;
  max-height: 100%;
}

body {
  @include responsive-min-width-m {
    padding: rem-calc(16);
  }

  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: $primary-font-color;
  font-family: $default-body-font;
  font-size: $default-font-size;
  font-weight: $default-font-weight;
  height: 100%;
  line-height: $default-line-height;
  position: relative;
  text-rendering: optimizeLegibility;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $default-heading-font;
  color: $primary-font-color;
  font-weight: 300;
  line-height: 1em;
  margin: 0 0 rem-calc(15);
}

h1 {
  @include responsive-min-width-m {
    font-size: rem-calc(72);
  }
  
  display: block;
  font-size: rem-calc(52);
  font-weight: 400;
  margin-bottom: rem-calc(24);
  line-height: 100%;
  width: 100%;
}


h2 {
  @include responsive-min-width-l {
    font-size: rem-calc(30);
  }

  display: inline-block;
  font-size: rem-calc(22);
  line-height: 1.45em;
  margin-bottom: .7em;
}

h3 {
  color: $primary-highlight;
  font-size: 1em;
  font-weight: 600;
  line-height: 1.6em;
  margin-bottom: .7em;
  text-transform: uppercase;
}

h4,
h5,
h6 {
  font-size: 1em;
  font-weight: 300;
  margin-bottom: 1em;
}

ul,
p {
  margin: 0 0 1.5em;
}

li {
  margin-bottom: .8em;

  &:last-child {
    margin-bottom: 0;
  }
}

a {
  border-bottom: 0 solid;
  color: $primary-highlight;
  cursor: pointer;
  text-decoration: none;
  transition: all .15s ease-out;

  &:hover {
    color: darken($primary-highlight, 5%);
  }

  &:focus {
    outline: none;
  }
}

img {
  height: auto;
  max-width: 100%;
}

p {
  &:last-child {
    margin-bottom: 0;
  }
}

strong,
b {
  font-weight: 600;
}

button {
  &:focus {
    outline: none;
  }
}

@keyframes welcomeAnimation {
  0% {
    transform: translateY(-1%);
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#main {
  animation: 1s ease-out 0s 1 welcomeAnimation; 
}

// .elements-core {
//   animation: 1.25s ease fadeIn; 
// }
