// Breakpoints
$breakpoints: (
  s: 480,
  m: 737,
  l: 768,
  xl: 1040,
  xxl: 1500,
  xxxl: 1800
);

//base font size for rem calculation
$rem-base: 16;

// Fonts
$default-body-font: 'Zen Maru Gothic', sans-serif;
$default-heading-font: 'Goudy Bookletter 1911', serif;
$default-font-size: $rem-base + px;
$default-font-weight: 300;
$default-line-height: 2;

// Global Borders, Paddings and Margins
$default-border-radius: .25rem;
$max-width: 70rem;
$nav-height: 5rem;

// Grid
$grid-column-count: 12;
$grid-gutter-width: 1.5%;
$grid-column-padding: 2rem;

// Colours
$primary-font-color: #3f3f3f;
$secondary-font-color: #fff;
$pink: #EED3D8;
$green: #2C5047;

$primary-background: #fff;
$primary-shadow: #fcfdfe;
$primary-darken: #f0f4f7;

$primary-highlight: #653CD8;

// Work Backgrouds
$core: #33475A;
$search: #6D6C7C;
$onboarding: #A3B3C3;
