.footer {
  @include responsive-min-width-m {
    text-align: left;

    br {
      display: none;
    }
  }

  text-align: center;
  padding: rem-calc(8) 0  rem-calc(32);
}