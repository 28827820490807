.navbar {
  &__container{
    @include responsive-min-width-l {
      max-width: calc(#{$max-width} + 6%);
      padding-left: 3%;
      padding-right: 3%;
    }
    
    align-items: center;
    display: flex;
    height: $nav-height;
    justify-content: space-between;
    margin: 0 auto;
    max-width: calc(#{$max-width} + 4rem);
    padding-left: 2rem;
    padding-right: 2rem;
    width: 100%;
  }

  &__logo {
    svg {
      display: block;
      
      path {
        transition: fill .15s ease-out;

        .elements-onboarding & {
          fill: lighten($onboarding, 20%);
        }

        .error-page & {
          fill: $green;
        }
      }
    }

    &:hover svg path {
      fill: darken($pink, 7.5%);

      .elements-onboarding & {
        fill: lighten($onboarding, 15%);
      }

      .error-page & {
        fill: lighten($green, 15%);
      }
    }
  }

  &__nav {
    > ul {
      display: flex;
      list-style: none;
      margin:0;
      padding: 0;
    }
  }

  &__item {
    @include responsive-min-width-m {
      margin-right: rem-calc(8); 
    }

    margin: 0 0 0 0;
    padding: 0;
    position: relative;

    > a,
    > span {
      color: $pink;
      display: block;
      font-size: rem-calc(14);
      line-height: rem-calc(20);
      padding: rem-calc(16);

    }
    > a:hover {
      color: darken($pink, 7.5%);
    }

    > span {
      display: flex;
      align-items: flex-end;

      &:after {
        background: url('../../images/icons/down-chevron.svg');
        content: '';
        display: block;
        height: rem-calc(16);
        margin-left: rem-calc(4);
        width: rem-calc(12);
      }
    }

    .elements-onboarding & {
      > a,
      > span {
        color:white;
      }

      > a:hover {
        color: darken(white, 5%)
      }

      > span::after {
        background: url('../../images/icons/down-chevron-white.svg');
      }
    }

    .error-page & {
      > a,
      > span {
        color:$green;
      }

      > a:hover {
        color: darken($green, 5%)
      }

      > span::after {
        background: url('../../images/icons/down-chevron-green.svg');
      }
    }

    &:hover .navbar__subnav {
      height: auto;
      opacity: 1;
      overflow: visible;
      padding: 0 0 rem-calc(4);
      transform: translateY(0);
    }
  }

  &__subnav {
    $subnav-width: rem-calc(200);
    $arrow-size: rem-calc(10);
    background: white;
    box-shadow: 0 rem-calc(4) rem-calc(4) rgba(0,0,0,.25);
    height: 0;
    left: 50%;
    list-style: none;
    margin: 0 0 0 calc(-#{$subnav-width} / 2);
    opacity: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 100%;
    transform: translateY(rem-calc(-16));
    transition: transform .25s, opacity .25s;
    width: $subnav-width;
    border-radius: rem-calc(16);

    &::before {
      border-bottom: $arrow-size solid white;
      border-left: $arrow-size solid transparent;
      border-right: $arrow-size solid transparent;
      content: '';
      height: 0; 
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top:  -$arrow-size;
      width: 0; 
    }

    li {
      display: block;
      margin: 0;
      text-align: center;

      &:first-of-type a {
        padding-top: rem-calc(16);
      }

      &:last-of-type a {
        padding-bottom: rem-calc(16);
      }
    }
  }

  &__sub-link {
    padding: rem-calc(8) 0;
    display: block;
    color: $green;
    line-height: 1;

    &:hover {
      color: darken($green, 15%);
    }
  }
}