.featured-work {
  $colors: $core, $search, $onboarding;

  .container {
    @include responsive-max-width-l {
      padding: 0 rem-calc(16);
    }
  }

  &__link {
    display: block;

    @for $i from 1 through length($colors) {
      &:nth-child(#{length($colors)}n+#{$i}) .featured-work__item {
        background-color: nth($colors, $i);

        &:hover {
          background-color: scale-color(nth($colors, $i), $lightness: -10%);
        }
      }
    }
  }

  &__item {
    @include responsive-min-width-m {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: rem-calc(32);
      min-height: rem-calc(400);
      padding: rem-calc(8) rem-calc(40);
    }
    
    @include responsive-min-width-l {
      padding-left: rem-calc(112);
      padding-right: rem-calc(112);
    }

    background-repeat: no-repeat;
    background-size: cover;
    border-radius: $default-border-radius;
    margin-bottom: rem-calc(16);
    overflow: hidden;
    padding: rem-calc(32) rem-calc(8);
    transition: background-color .15s;

    img {
      @include responsive-min-width-m {
        width: 65%;
      }
      
      @include responsive-min-width-l {
        width: 100%;
      }

      max-width: rem-calc(480);
    }

    &--elements-core {
      @include responsive-max-width-m {
        background-position: left -16rem top;
      }
      
      @include responsive-min-width-m{
        padding-left: rem-calc(0);
      }
      
      @include responsive-min-width-l{
        padding-left: rem-calc(88);
      }
      
      background-image: url('../../images/work/elements-core/hero-bg.svg');

      img {
        max-width: rem-calc(430);
      }
    }

    &--elements-search {
      @include responsive-max-width-m {
        padding-left: 0;
        padding-right: 0;
        background-size: 211% 84%;
        background-position: top left -46vw;

        img {
          margin-left: rem-calc(32);
          margin-bottom: rem-calc(24);
          max-width: rem-calc(600);
        }
      }

      background-image: url('../../images/work/elements-search/hero-bg.svg');
    }

    &--elements-onboarding {      
      @include responsive-max-width-m {
        padding-left: 0;
        padding-right: 0;
        background-size: 211% 84%;
        background-position: top left -46vw;
      }

      background-image: url('../../images/work/elements-onboarding/hero-bg.png');
      padding-left: 0;
      
      img {
        @include responsive-max-width-m {
          margin-bottom: rem-calc(24);
          margin-left: -(rem-calc(64));
        }

        @include responsive-max-width-l {
          max-width: rem-calc(400);
        }

        max-width: rem-calc(530);
      }
    }
  }

  &__details {
    @include responsive-min-width-m {
      padding: 0;
    }

    padding: 0 rem-calc(24);
    text-align: right;

    h2,
    span {
      color: white;
      display: block;
    }

    h2 {
      @include responsive-min-width-m {
        line-height: 1;
        margin: 0;
      }

      font-size: rem-calc(48);
      margin: 0 0 rem-calc(4);
      line-height: 120%;
    }

    span {
      opacity: .5;
      font-size: rem-calc(24);
      line-height: 1;
    }
  }
}