.homepage {
  &__hero {
    @include responsive-min-width-m {
      border-radius: $default-border-radius;
      margin-bottom: rem-calc(32);
      padding-bottom: rem-calc(176);
    }
    
    background-color: $green;
    color: $pink;
    margin-bottom: rem-calc(16);
    padding-bottom: rem-calc(62);
    
    header {    
      @include responsive-min-width-m {
        margin-bottom: rem-calc(40);
        padding-top: rem-calc(80);
      }

      margin-bottom: rem-calc(20);
      padding-top: rem-calc(40);
    }

    section {
      max-width: rem-calc(720);

      p {
        font-size: rem-calc(18);
        margin-bottom: rem-calc(16);
        line-height: 170%;

        &:last-of-type {
          margin-bottom: 0;
        }

        a {
          color: $pink;
          border-bottom: 1px dotted;

          &:hover {
            opacity: .7;
          }
        }
      }
    }
  }
  
  &__title {
    &__name {
      background-color: $pink;
      color: $green;
      display: inline-block;
      font-family: 'Gabriela', serif;
      letter-spacing: rem-calc(-5);
      padding: 0 rem-calc(8);
    }
  }

  &__sub-title {
    @include responsive-min-width-m {
      font-size: rem-calc(48);
    }

    font-family: 'Zeyada', cursive;
    font-size: rem-calc(40);
    margin: 0;
  }

  &__title,
  &__sub-title {
    color: $pink;
  }
}