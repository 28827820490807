  .home-socials {
    display: flex;
    list-style: none;
    margin: rem-calc(32) 0 0 0;
    padding: 0;

    li {
      @include responsive-min-width-m {
        margin-right: rem-calc(10);
      }

      margin: 0 rem-calc(12) 0 0;

      &:last-of-type {
        margin: 0;
      }
    }
    
    a {
      @include responsive-min-width-m {
        height: rem-calc(32);
        width: rem-calc(32);
      }

      @include responsive-max-width-m {
        img {
          width: rem-calc(24);
        }
      }

      align-items: center;
      background-color: $pink;
      border-radius: 100%;
      box-shadow: 0 rem-calc(6) rgba(0,0,0, .3);
      display: flex;
      height: rem-calc(48);
      justify-content: center;
      width: rem-calc(48);

      &:hover {
        transform: translateY(rem-calc(6));
        box-shadow: none;
      }
    }
  }